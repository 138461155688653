
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

#about {
	background-color: rgba(116, 0, 2, 0.39);
}


.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
            user-select: none;
		-webkit-tap-highlight-color: transparent;
}

button {
	width: 250px;
	height: 50px;
	cursor: pointer;
	background: #7400024b;
	margin: 10px;
	border: none;
	border-radius: 10px;
	box-shadow: -5px -5px 15px rgba(68, 68, 68, 0.39), 5px 5px 15px rgba(34, 34, 34, 0.425), inset 5px 5px 10px rgba(68, 68, 68, 0.425), inset -5px -5px 10px rgba(34, 34, 34, 0.397);
	color: #74000263;
	font-size: 16px;
}

button:hover {
	box-shadow: -5px -5px 15px rgba(68, 68, 68, 0.411), 5px 5px 15px rgba(34, 34, 34, 0.425), inset 5px 5px 10px rgba(34, 34, 34, 0.425), inset -5px -5px 10px rgba(68, 68, 68, 0.425);
	font-size: 15px;
	transition: 500ms;
}

button:focus {
	outline: none;
}

.red:hover {
	color: #f07171;
	text-shadow: 0px 0px 10px #f07171;
}

.green:hover {
	color: #93f071;
	text-shadow: 0px 0px 10px #93f071;
}

.blue:hover {
	color: #71b7f0;
	text-shadow: 0px 0px 10px #71b7f0;
}

.purple:hover {
	color: #b971f0;
	text-shadow: 0px 0px 10px #b971f0;
}

.abtInnerWrapper{
	background: rgba(51, 51, 51, 0.459);
}




/* Dropdown Button */
.dropbtn {
	background-color: #04AA6D;
	color: white;
	padding: 16px;
	font-size: 16px;
	border: none;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
	position: relative;
	display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
	display: none;
	position: absolute;
	background-color: #f1f1f1;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #ddd;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {background-color: #3e8e41;}

.card{
	position: relative;
	width: 300px;
	height: 400px;
	margin: 0 auto;
	background: #000;
	border-radius: 15px;
	box-shadow: 0 15px 60px rgba(0,0,0,0.5);
}

.areaHolderP0{
	position: relative;
	width: 400px;
	height: 400px;
}

.face{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display:flex;
	justify-content: center;
	align-items: center;
	background-image:linear-gradient(
		40deg,
		#ffd000 0%,
		#d33557ee 45%,
		#7cffee 100%
	);
	border-radius: 15px;
}

.face1{
	box-sizing: border-box;
	padding: 10px;
}

h2 {
	margin: 0;
	padding: 0;
}

.javascript{
	background-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: #23dfd6ee;
}

.card:hover .face.face2{
	height: 60px;
}

h2{
	font-size: 2em;
}

.card:nth-child(1) .face.face2 {
	background-image:url("https://github.com/zemingzhang1/To_Do/blob/main/ezgif.com-gif-maker.gif?raw=true");
	background-size: cover;
	border-radius: 15px;
}

.face2{
	transition: 0.50s;
}

.face2 h2{
	margin: 0;
	padding: 0;
	font-size: 6em;
	color:rgb(255, 255, 255);
	transition: 0.5s;
	text-shadow: 0 15px 35px rgba(0, 0, 0, 0.65);
	z-index: 10;
}

h5{
	color:white;
	text-shadow: 1px 1px 10px rgb(37, 4, 5), 0 0 45px rgb(0, 0, 0), 0 0 10px rgb(73, 3, 27);
}






/* .collapsible.slow {
    position: relative;
    overflow: hidden;
    padding-bottom: 0.5em;
    transition: height 0.5s ease-out;
}
.collapsible.slow > * {
    display: none;
}
.collapsible.slow > p:first-child, .collapsible.slow.open > *, .collapsible.slow.ready > * {
    display: revert;
}

.collapsible.slow > .toggler {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    text-align: center;
    cursor: pointer;
}
.collapsible.slow > .toggler::after {
    content: "\25bc";
}
.collapsible.slow.open > .toggler::after {
	content: "\25bb";
} */
